<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <el-tabs>
        <el-tab-pane label="基础信息">
          <el-form :model="form" size="small" label-position="left">
            <el-form-item
              label="配件名称"
              :label-width="formLabelWidth"
              prop="name"
            >
              <el-input v-model="form.name" placeholder="配件名称"></el-input>
            </el-form-item>
            <el-form-item label="数量" :label-width="formLabelWidth">
              <el-input v-model="form.qogu" placeholder="数量"></el-input>
            </el-form-item>
            <el-form-item label="使用寿命" :label-width="formLabelWidth">
              <el-input
                v-model="form.lifetime"
                placeholder="使用寿命"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品编号" :label-width="formLabelWidth">
              <el-input
                v-model="form.numbering"
                placeholder="商品编号"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="选择信息">
          <el-form :model="form" size="small" label-position="left">
            <el-form-item label="选择分类" :label-width="formLabelWidth">
              <el-cascader
                :options="options"
                :props="optionProps"
                v-model="form.class_id"
                filterable
                clearable
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="选择商品" :label-width="formLabelWidth">
              <el-cascader
                :options="options2"
                :props="optionProps"
                v-model="form.mp_id"
                filterable
                clearable
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="关联任务" :label-width="formLabelWidth">
              <el-cascader
                placeholder="关联任务"
                :options="optionsTask"
                :props="optionPropsTask"
                v-model="form.task_id"
                filterable
              ></el-cascader>
            </el-form-item>

            <el-form-item label="是否为拆分配件" :label-width="formLabelWidth">
              <el-radio v-model="form.oper_state" :label="0">不是</el-radio>
              <el-radio v-model="form.oper_state" :label="1">是</el-radio>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="配件图片">
          <el-upload
            :headers="token"
            accept=".jpg,.png"
            :on-remove="imgRemove"
            :limit="1"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess"
            name="image"
          >
            <el-button size="small" type="primary">上传配件图</el-button>
          </el-upload>
        </el-tab-pane>
        <el-tab-pane label="配件详情">
          <el-upload
            :headers="token"
            accept=".jpg,.png"
            :on-remove="imgRemove2"
            :limit="10"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess2"
            name="image"
          >
            <el-button size="small" type="primary"
              >上传详情图</el-button
            ></el-upload
          >
        </el-tab-pane>
        <el-tab-pane label="配件轮播">
          <el-upload
            :headers="token"
            accept=".jpg,.png"
            :on-remove="imgRemove3"
            :limit="10"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess3"
            name="image"
          >
            <el-button size="small" type="primary"
              >上传商品轮播图</el-button
            ></el-upload
          >
        </el-tab-pane>
        <el-tab-pane label="确认添加">
          <el-button @click="setAdd" type="primary" size="medium"
            >确认添加</el-button
          >
        </el-tab-pane>
      </el-tabs>
      <!-- 头 -->
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getAdd, getOption, getOption2 } from "@/api/psi/accessory.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      token: {
        "Accept-Token": window.sessionStorage.getItem("token"),
      },
      optionsTask: [],
      options: [],
      options2: [],
      optionProps: {
        value: "id",
        label: "name",
        children: "sub",
      },
      optionPropsTask: {
        value: "id",
        label: "name",
        children: "son",
        disabled: "state",
      },
      fileList: [],
      fileList2: [],
      //总页数
      exp: {
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page: 1,
        list_rows: 4,
      },
      //导航
      nav: [],
      options: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: "一级",
        },
        {
          id: 2,
          name: "二级",
        },
      ],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      info: {},
      // 添加信息表单
      form: {
        name: "",
        img: "",
        info_img: [],
        oper_state: 0,
        qogu: "",
        lifetime: "",
        mp_id: [],
        class_id: [],
        numbering: "",
        task_id: [],
        slideshow: [],
      },
      //表单名称长度
      formLabelWidth: "120px",
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: "",
      //表格数据
      tableData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //导入合同事件
    imgSuccess(response, file, fileList) {
      this.form.img = response.data.url;
    },
    exceed() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
    //移除合同的事件
    imgRemove(file, fileList) {
      this.form.img = "";
    },
    //导入图片事件
    imgSuccess2(response, file, fileList) {
      console.log(response);
      this.form.info_img.push(response.data.url);
    },

    //移除图片的事件
    imgRemove2(file, fileList) {
      console.log(fileList);
      this.form.info_img = [];
      fileList.forEach((item) => {
        if (item.response) {
          this.form.info_img.push(item.response.data.url);
        } else {
          this.form.info_img.push(item.url);
        }
      });
      console.log(this.form);
    },
    //导入图片事件
    imgSuccess3(response, file, fileList) {
      console.log(response);
      this.form.slideshow.push(response.data.url);
    },

    //移除图片的事件
    imgRemove3(file, fileList) {
      console.log(fileList);
      this.form.slideshow = [];
      fileList.forEach((item) => {
        if (item.response) {
          this.form.slideshow.push(item.response.data.url);
        } else {
          this.form.slideshow.push(item.url);
        }
      });
      console.log(this.form);
    },
    clear() {
      this.form = {};
    },
    change(val) {
      console.log(val);
    },
    goBack() {
      this.$router.go(-1);
    },
    //搜索事件
    search(e) {
      console.log("搜索信息: " + e);
    },
    //添加事件--提交事件
    async setAdd(e) {
      console.log(this.form);
      let info = await getAdd(this.form);
      this.judge(info);
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        this.$router.push("/accessory");
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: "error",
        });
        return;
      }
    },
    async setOption() {
      let info = await getOption();
      let info2 = await getOption2();
      let optionsTask = await this.$getOptionTask();
      this.optionsTask = optionsTask.data.data;
      this.options = info.data.data;
      this.options2 = info2.data.data;
      console.log();
    },

    //添加事件 退出弹窗
    userClose(e) {
      this.form = {};
      console.log("close");
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {
    this.setOption();
    this.getNav = this.$getNavn("/accessory");
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.tab-top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 10px;
}
.tab-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  margin-right: 10px;
}
.tab-info > span {
  display: flex;
  width: 80px;
}
.info-bt {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>